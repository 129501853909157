<template>
    <div>
     <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      <el-form ref="addform" :model="form" label-width="120px"  size="mini" >
        <div >
          <el-form-item label="测量人:" v-if="type==1">
            <el-select v-model="form.surveyorId"  placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.realName"
                
                :value="item.userId">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="完结说明:"  v-if="type==2">
            <el-input type="textarea" v-model="form.reason"  :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" ></el-input>
          </el-form-item>
          <el-form-item label="取消原因:"  v-if="type==3">
             <el-input type="textarea" v-model="form.reason"  :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {bzApply_acceptance,bzApply_cancel,bzApply_finish,user_listByPost} from '../../RequestPort/report/reportApply'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      title:'',
      form:{},
      userList:[],
    }
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        if(this.$nextTick){
            if(this.type==1){
                this.title = '报装受理'
                user_listByPost({code:'aj004'}).then(res=>{
                    this.userList = res.data;
               })
            }
            if(this.type==2){
                this.title = '报装完结'
            }
            if(this.type==3){
                this.title = '报装取消'
            }
            this.form.id = this.id;
        }
      },
      clearnF(){
          this.form = {
              surveyorId:'',
              reason:'',
              surveyorName:'',
              id:''
          }
      },
      saveadd(){
          if(this.type==1){
             var obj = this.userList.find(item=>{
                return item.userId == this.form.surveyorId
             })
            this.form.surveyorName = obj.realName;
              bzApply_acceptance(this.form).then(res=>{
                this.$message.success('报装受理成功！')
                this.setdialogVisible(false);
                this.$parent.getList()
              })
          }
          if(this.type==2){
            this.form.surveyorId =0
              bzApply_finish(this.form).then(res=>{
                this.$message.success('报装完结成功！')
                this.setdialogVisible(false);
                this.$parent.getList()
              })
          } 
          if(this.type==3){
            this.form.surveyorId =0
              bzApply_cancel(this.form).then(res=>{
                this.$message.success('报装取消成功！')
                this.setdialogVisible(false);
                this.$parent.getList()
              })
          }    
      },
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>
