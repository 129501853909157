import axios from '@/common/js/request'
export function getDateTime(data){
    var myDate = new Date();
    var month = parseInt(myDate.getMonth())+1;
    var month1 = month+1;
    month = (Array(2).join(0) + month).slice(-2)
    var  day = myDate.getDate();
    day = (Array(2).join(0) + day).slice(-2);
    var curDate= ''
    if(month1==13){
         curDate = new Date(myDate.getFullYear()+1+'/01/1');
    }else{
         curDate = new Date(myDate.getFullYear()+"/"+month1+'/1');
    }
    const preDate = new Date(curDate.getTime() - 24*60*60*1000).getDate();
    var startdate = myDate.getFullYear()+"-"+month+"-01";
    var enddate = myDate.getFullYear()+"-"+month+"-"+preDate;
    return [startdate,enddate];
}

export function bzApply_page(data){
    const bzApply_page = axios({
        url: '/bzApply/page',
        method: 'get',
        params:data
    })
    return bzApply_page
}

export function bzApply_pageCompany(data){
    const bzApply_pageCompany = axios({
        url: '/bzApply/pageCompany',
        method: 'get',
        params:data
    })
    return bzApply_pageCompany
}
export function bzApply_getOperationDetail(data){
    const bzApply_getOperationDetail = axios({
        url: '/bzApply/getOperationDetail',
        method: 'get',
        params:data
    })
    return bzApply_getOperationDetail
}

export function user_listByPost(data){
    const user_listByPost = axios({
        url: '/user/listByPost',
        method: 'get',
        params:data
    })
    return user_listByPost
}
export function bzApply_getApplyInfo(data){
    const bzApply_getApplyInfo = axios({
        url: '/bzApply/getApplyInfo',
        method: 'get',
        params:data
    })
    return bzApply_getApplyInfo
}
export function bzApply_pageShareSetting(data){
    const bzApply_pageShareSetting = axios({
        url: '/bzApply/pageShareSetting',
        method: 'get',
        params:data
    })
    return bzApply_pageShareSetting
}
export function bzApply_acceptance(data){
    const bzApply_acceptance = axios({
        url: '/bzApply/acceptance',
        method: 'post',
        data:data
    })
    return bzApply_acceptance
}
export function bzApply_cancel(data){
    const bzApply_cancel = axios({
        url: '/bzApply/cancel',
        method: 'post',
        data:data
    })
    return bzApply_cancel
}
export function bzApply_finish(data){
    const bzApply_finish = axios({
        url: '/bzApply/finish',
        method: 'post',
        data:data
    })
    return bzApply_finish
}

export function bzApply_save(data){
    const bzApply_save = axios({
        url: '/bzApply/save',
        method: 'post',
        data:data
    })
    return bzApply_save
}
export function bzApply_shareSetting(data){
    const bzApply_shareSetting = axios({
        url: '/bzApply/shareSetting',
        method: 'get',
        params:data
    })
    return bzApply_shareSetting
}