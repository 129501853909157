<template>
    <div>
     <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      <el-form ref="addform" :model="form" label-width="125px" size="mini" v-if="type==1">
          <el-form-item :label="form.type==1?'法人姓名:':'产权人姓名:'" prop="positionName">
           {{form.name}}
          </el-form-item>
          <el-form-item :label="form.type==1?'法人身份证号:':'身份证号:'" prop="positionName">
           {{form.idcard}}
          </el-form-item>
          <el-form-item  label="公司名称"  prop="positionName" v-if="form.type==1">
           {{form.corporation}}
          </el-form-item>
          <el-form-item :label="form.type==1?'公司地址:':'地址:'" prop="positionName">
           {{form.address}}
          </el-form-item>
          <el-form-item label="联系人电话:" prop="positionName">
           {{form.tel}}
          </el-form-item>
          <el-form-item label="用途:" prop="positionName">
           {{form.useDescName}}
          </el-form-item>
          <el-form-item :label="form.type==1?'法人身份证:':'产权人身份证:'">
           <el-image v-for="item,index in form.imgListAll" :key="index"  :src="item" :style="index==0?'width:161px;height:100px':'width:161px;height:100px;margin-left:20px'"  :preview-src-list="[item]"></el-image>
          
           <span v-if="form.imgListAll.length<=0">未上传</span>
          </el-form-item>
          <el-form-item :label="form.type==1?'营业执照:':'房产证(购房合同):'" prop="positionName">
              <el-image :src="item" style="width:100px;height:100px;margin-right:18px" v-for="item,key of form.contractImageList" :preview-src-list="form.contractImageList" :key="key"></el-image>
                <span v-if="form.contractImageList&&form.contractImageList.length==0">未上传</span>
          </el-form-item>
      </el-form>
      <el-form ref="addform" :model="form" label-width="120px" size="mini" v-if="type==2">
        <div>
         <div><span class="icon_line"></span><span class="title">受理信息</span></div>
          <el-form-item label="受理人:" prop="positionName">
           {{form.solveUser}}
          </el-form-item>
          <el-form-item label="受理时间:" prop="positionName">
           {{form.solveStatusTime}}
          </el-form-item>
           <el-form-item label="测量人:" prop="positionName">
           {{form.surveyUserName}}
          </el-form-item>
        </div>
        <div v-if="form.surveyCreateTime">
           <div><span class="icon_line"></span><span class="title">勘查信息</span></div>
          <el-form-item label="勘察人:" prop="positionCode">
            {{form.surveyUserName}}
          </el-form-item>
          <el-form-item label="勘察时间:" prop="positionCode">
            {{form.surveyCreateTime}}
          </el-form-item>
          <el-form-item label="勘察结果:" prop="positionCode">
            {{form.surveyResult}}
          </el-form-item>
          <el-form-item label="" prop="positionCode">
           <el-image 
                style="width: 100px; height: 100px"
                :src="form.surveyImageUrlList[0]" 
                :preview-src-list="form.surveyImageUrlList">
            </el-image>
            <span style="margin-left:10px;">共{{form.surveyImageUrlList.length}}张</span>
          </el-form-item>
        </div>
        <div v-if="form.finishStatusTime">
         
          <div><span class="icon_line"></span><span class="title">完结信息</span></div>
          <el-form-item label="完结人:">
            <span style="word-break:break-all">{{form.finishUser}}</span>
          </el-form-item>
          <el-form-item label="完结时间:">
            <span style="word-break:break-all">{{form.finishStatusTime}}</span>
          </el-form-item>
          <el-form-item label="完结说明:">
            <span style="word-break:break-all">{{form.finishReason}}</span>
          </el-form-item>
        </div>
        <div v-if="form.cancelStatusTime">
          <div><span class="icon_line"></span><span class="title">取消信息</span></div>
          <el-form-item label="取消环节:" v-if="form.cancelNode">
            <span style="word-break:break-all">{{form.cancelNode}}</span>
          </el-form-item>
          <el-form-item label="取消人:">
            <span style="word-break:break-all">{{form.cancelUser}}</span>
          </el-form-item>
          <el-form-item label="取消时间:">
            <span style="word-break:break-all">{{form.cancelStatusTime}}</span>
          </el-form-item>
          <el-form-item label="取消原因:">
            <span style="word-break:break-all">{{form.mark}}</span>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <!-- <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button> -->
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {bzApply_getOperationDetail,bzApply_getApplyInfo} from '../../RequestPort/report/reportApply'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      title:'',
      form:{imgListAll:[]}
    }
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        this.clearnF();
        if(this.$nextTick){
            if(this.type==1){
                bzApply_getApplyInfo({id:this.id}).then(res=>{
                this.form = res.data;
                var imgList = this.form.idcard1Image.split(',')
                var imgList2 = this.form.idcard2Image.split(',')
                this.form.imgListAll = []
                imgList.forEach(el => {
                  if(el){
                    this.form.imgListAll.push(el)
                  }
                });
                imgList2.forEach(el => {
                  if(el){
                    this.form.imgListAll.push(el)
                  }
                });
                if(this.type==1){
                    if(this.form.type==1){
                        this.title = '申请信息';
                    }
                    if(this.form.type==2){
                        this.title = '申请人信息';
                    }
                }
                
                })
            }
            if(this.type==2){
                this.title = '报装申请处理详情'
                bzApply_getOperationDetail({id:this.id}).then(res=>{
                this.form = res.data;
               
                
                })
            }
            
        }
      },
      clearnF(){
        this.form={
          type:'',
          address:'',
          contractImageList:[],
          corporation:'',
          idcard:'',
          idcard1Image:'',
          idcard2Image:'',
          name:'',
          tel:'',
          type:'',
          useDesc:'',
          status:'',
          surveyFlag:'',
          solveStatusTime: "",
          solveUserId: 1,
          solveUser: "",
          cancelStatusTime: "",
          cancelUserId: 0,
          cancelUser: "",
          cancelNode: "",
          mark: "",
          finishStatusTime: "",
          finishUserId: '',
          finishUser: "",
          finishReason: "",
          surveyUserName: "",
          surveyResult: "",
          surveyImageUrlList: [],
          surveyCreateTime: "",
          imgListAll:[]
              }
    },
      
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>
<style scoped>
.icon_line{
  width: 3px;
height: 16px;
background: #1082FF;
display: inline-block;
}
.title{
  font-weight: 400;font-size: 16px; margin-left: 5px;height: 16px; line-height: 16px;
}
</style>
